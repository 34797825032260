
import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'

/**
 * Ausklappbarer Datepicker, der das passende Datumsformat ausgibt.
 */
@Component({})
export default class DatePicker extends Vue {
  /**
   * String des zu benutzenden Labels für die Combobox
   */
  @Prop({ type: String, required: false, default: '' })
  public label!: string

  /**
   * Gibt an, ob es sich um ein Pflichtfeld handelt
   */
  @Prop({ type: Boolean, required: false, default: false })
  public required!: boolean

  /**
   * Regel für die Combobox
   */
  @Prop({ type: Array, required: false, default: () => [] })
  public rule!: []

  /**
   * Beinhaltet das Datum aus dem Datumsfeld
   */
  @Prop({ type: String, required: true })
  public value!: string

  /**
   * String des zu benutzenden Hints für die Combobox
   */
  @Prop({ type: String, required: false, default: '' })
  public hint!: string

  /**
   * Gibt an, ob der Inhalt des Felds löschbar sein soll
   */
  @Prop({ type: Boolean, required: false, default: false })
  public clearable!: boolean

  /**
   * Gibt an, welche Daten auswählbar sind
   */
  @Prop({ type: Function, required: false })
  public allowedDates!: Function

  /**
   * Gibt das Datum zurück.
   *
   * @returns gespeichertes Datum.
   */
  public get pickerValue(): string {
    return this.value
  }

  /**
   * Gibt das Datum an die Elternkomponente weiter.
   *
   * @param value - Wert
   */
  public set pickerValue(value: string) {
    this.$emit('input', value)
  }

  /**
   * Gibt an, ob der Datepicker eingeblendet werden soll
   */
  public showPicker = false

  /**
   * Gibt das Datum im Format der gewählten Sprache zurück für die Ausgabe
   *
   * @returns string - Datum in der gewählten Sprache
   */
  public get formattedDate(): string {
    const lang = this.$i18n.locale

    if (!this.value) {
      return ''
    }

    return moment(this.value, 'YYYY-MM-DD', lang).format('L')
  }

  /**
   * Prüft, ob es sich bei dem Label um einen bereits bestehenden Sprachkey
   * oder einen fertigen Sprachstring handelt.
   *
   * @returns string - Sprachstring
   */
  public get checkedLabel(): string {
    if (this.$te(this.label)) {
      return this.$t(this.label).toString()
    }
    return this.label
  }

  /**
   * Prüft, ob es sich bei dem Hint um einen bereits bestehenden Sprachkey
   * oder einen fertigen Sprachstring handelt.
   *
   * @returns string - Sprachstring
   */
  public get checkedHint(): string {
    if (this.$te(this.hint)) {
      return this.$t(this.hint).toString()
    }
    return this.hint
  }
}
